<template>
<div :class="'' + ( isMobile ? 'mobile-page-layout' : '')">
    <slot v-if="isMobile">
        <div class="mobile-page-header">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <router-link v-if="isMobile && !sidebarShow" :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div v-if="sidebarShow" class="mobile-page-header-icon pointer back-icon" @click.prevent="hideSidebar()">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </div>

                    <div class="mobile-page-header-icon">
                        <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path class="fa-primary" d="M522.7 85.47C510.9 91.39 496.5 86.59 490.5 74.73C484.6 62.88 489.4 48.46 501.3 42.53L565.3 10.53C577.1 4.606 591.5 9.412 597.5 21.27C603.4 33.12 598.6 47.54 586.7 53.47L522.7 85.47zM0 200C0 186.7 10.75 176 24 176H104C117.3 176 128 186.7 128 200C128 213.3 117.3 224 104 224H24C10.75 224 0 213.3 0 200zM512 200C512 186.7 522.7 176 536 176H616C629.3 176 640 186.7 640 200C640 213.3 629.3 224 616 224H536C522.7 224 512 213.3 512 200zM74.73 10.53L138.7 42.53C150.6 48.46 155.4 62.88 149.5 74.73C143.5 86.59 129.1 91.39 117.3 85.47L53.27 53.47C41.41 47.54 36.61 33.12 42.53 21.27C48.46 9.412 62.88 4.606 74.73 10.53V10.53zM320 512C303 512 286.7 505.3 274.7 493.3C262.7 481.3 256 464.1 256 448H384C384 464.1 377.3 481.3 365.3 493.3C353.3 505.3 336.1 512 320 512z" />
                            <path class="fa-secondary" d="M352 32V51.2C425 66.03 480 130.6 480 208V226.8C480 273.9 497.3 319.2 528.5 354.4L535.9 362.7C544.3 372.2 546.4 385.6 541.2 397.1C536 408.6 524.6 416 512 416H128C115.4 416 103.1 408.6 98.81 397.1C93.65 385.6 95.71 372.2 104.1 362.7L111.5 354.4C142.7 319.2 160 273.9 160 226.8V208C160 130.6 214.1 66.03 288 51.2V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32H352z" />
                        </svg>
                    </div>
                    <div class="mobile-page-header-text">
                        <slot v-if="sidebarShow">تاریخچه هشدار</slot>
                        <slot v-if="!sidebarShow">هشدارها </slot>
                    </div>  
                </div>
                <div class="mobile-page-header-actions">
                    <div v-if="!widgetLoad && !sidebarShow" class="mobile-page-header-icon pointer" @click.prevent="open_alert_add_modal()">
                        <i class="header-uil-icon uil uil-plus-circle"></i>
                    </div>
                    <div class="mobile-page-header-menu">
                        <template>
                            <div class="text-center">
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                        <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                    </div>
                                </template>
                                <v-list class="header-help-list">
                                    <v-list-item v-for="(item, index) in menus" :key="index"  @click.prevent="get_modal(item.modal)">
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                                </v-menu>
                            </div>
                        </template>                 
                    </div>
                </div>
            </div>
        </div>
    </slot>

    <div  v-if="!isMobile" class="dashboard-widget-tabs dashboard-widget-pages">
        <div class="dashboard-widget-tabs-content padding-m" style="margin: 0;border: 0;padding: 0;">
            <h2>
                <!-- <i class="uil uil-bell"></i> -->
                <svg class="rightnav-svg rightnav-svg-page" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path class="fa-primary" d="M522.7 85.47C510.9 91.39 496.5 86.59 490.5 74.73C484.6 62.88 489.4 48.46 501.3 42.53L565.3 10.53C577.1 4.606 591.5 9.412 597.5 21.27C603.4 33.12 598.6 47.54 586.7 53.47L522.7 85.47zM0 200C0 186.7 10.75 176 24 176H104C117.3 176 128 186.7 128 200C128 213.3 117.3 224 104 224H24C10.75 224 0 213.3 0 200zM512 200C512 186.7 522.7 176 536 176H616C629.3 176 640 186.7 640 200C640 213.3 629.3 224 616 224H536C522.7 224 512 213.3 512 200zM74.73 10.53L138.7 42.53C150.6 48.46 155.4 62.88 149.5 74.73C143.5 86.59 129.1 91.39 117.3 85.47L53.27 53.47C41.41 47.54 36.61 33.12 42.53 21.27C48.46 9.412 62.88 4.606 74.73 10.53V10.53zM320 512C303 512 286.7 505.3 274.7 493.3C262.7 481.3 256 464.1 256 448H384C384 464.1 377.3 481.3 365.3 493.3C353.3 505.3 336.1 512 320 512z" />
                    <path class="fa-secondary" d="M352 32V51.2C425 66.03 480 130.6 480 208V226.8C480 273.9 497.3 319.2 528.5 354.4L535.9 362.7C544.3 372.2 546.4 385.6 541.2 397.1C536 408.6 524.6 416 512 416H128C115.4 416 103.1 408.6 98.81 397.1C93.65 385.6 95.71 372.2 104.1 362.7L111.5 354.4C142.7 319.2 160 273.9 160 226.8V208C160 130.6 214.1 66.03 288 51.2V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32H352z" /></svg>
                <span class="page-title-svg">هشدارها</span>
            </h2>
            <div class="dashboard-widget-h-btns">
                <a v-if="sidebarShow == true" href="" @click.prevent="hideSidebar()" class="m-left m-top tgju-btn tgju-btn-primary hide-pages-mobile-btn" v-tooltip="'هشدارها'"><i class="uil uil-list-ui-alt"></i> <span class="hide-m-min"> هشدارها </span></a>
                <a v-if="!widgetLoad" href="" @click.prevent="open_alert_add_modal()" class="m-left m-top tgju-btn tgju-btn-primary hide-pages-mobile-btn" v-tooltip="'افزودن هشدار'"><i class="uil uil-plus-circle"></i> <span class="hide-m-min">افزودن هشدار </span></a>
                <!-- <a v-if="sidebarShow != true" href="" @click.prevent="showSidebar()" class="m-left m-top tgju-btn tgju-btn-light hide-pages-mobile-btn" v-tooltip="'تاریخچه'"><i class="uil uil-history"></i> <span class="hide-m-min">تاریخچه </span></a> -->
                <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
            </div>
        </div> 
    </div>
    <div  v-if="!isMobile" class="mobile-btns-box show-pages-mobile-btn">
        <a v-if="sidebarShow == true" href="" @click.prevent="hideSidebar()" class="tgju-btn tgju-btn-primary" v-tooltip="'هشدارها'"><i class="uil uil-list-ui-alt"></i> <span> هشدارها </span></a>
        <a v-if="!widgetLoad" href="" @click.prevent="open_alert_add_modal()" class="tgju-btn tgju-btn-primary" v-tooltip="'افزودن هشدار'"><i class="uil uil-plus-circle"></i> <span>افزودن هشدار </span></a>
    </div>

    <div :class="'dashboard-widget-tabs p-0 ' + ( isMobile ? 'clear-block' : '')">
        <div class="sidebar-box">
            <slot v-if="widgetLoad">
                <div class="tgju-widget-content full-padding">
                    <div class="text-center p-2">
                        <div class="widget-loading"></div>
                    </div>
                </div>
            </slot>
            <slot v-else>
                <slot v-if="alerts.length">
                    <table class="widget-table widget-table-lg" v-if="sidebarShow == false">
                        <thead>
                            <tr>
                                <th class="w60 text-center">#</th>
                                <th>عنوان</th>
                                <th class="text-center hide-table-r hide-640">شاخص</th>
                                <th class="text-center hide-640">آخرین ‌قیمت</th>
                                <th class="hide-640 text-center">بیشترین</th>
                                <th class="hide-640 text-center">کمترین</th>
                                <th class="hide-table-r hide-640 text-center">نوع نوسان</th>
                                <th class="hide-table-r hide-1200 text-center">کانال‌های‌ دریافت</th>
                                <th class="w60 text-center">ویرایش</th>
                                <th class="w60 text-center">حذف</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="pointer" v-for="(alert, index) in alerts" :key="index" @click.prevent="showSidebar(alert.id)">
                                <td class="border-l bg-gray w60 text-center"> {{ index+1 }}</td>
                                <td class="border-l bg-gray"> {{ alert.title }}</td>
                                <td class="border-l text-center hide-table-r hide-640">{{ $helpers.json_pars(alert.asset)['label'] }}</td>
                                <td class="border-l text-center hide-640">
                                    {{ getPrice($helpers.json_pars(alert.asset)['item_id']) }}
                                </td>
                                <td class="border-l text-center hide-640">{{ alert.max }}</td>
                                <td class="border-l text-center hide-640">{{ alert.min }}</td>
                                <td class="border-l text-center hide-table-r">
                                    <span v-if="alert.type == 'exact' ">مقدار ثابت</span>
                                    <span v-if="alert.type == 'amount' ">مقدار نوسان</span>
                                    <span v-if="alert.type == 'percentage' ">درصد نوسان</span>
                                </td>
                                <td class="border-l text-center hide-table-r hide-1200">
                                    <i class="uil uil-envelope table-icons alert-t-btn" v-tooltip="'ایمیل'" :class="{'active': alert.notification_email}"></i>
                                    <i class="uil uil-bell table-icons alert-t-btn " v-tooltip="'پوش‌نوتیفیکیشن'" :class="{'active': alert.notification_push}"></i>
                                </td>
                                <td class="w60 text-center border-l"><i class="uil uil-edit-alt table-icons pointer" v-tooltip="'ویرایش'" @click.stop="open_alert_add_modal(alert.id)"></i></td>
                                <td class="w60 text-center"><i class="uil uil-trash table-icons red-color pointer" v-tooltip="'حذف'" @click.stop="removeAlert(alert.id)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </slot>

                <div  v-if="!alerts.length" class="noWidget noContent">
                    <div>
                        <i class="uil uil uil-bell noContent-icon" aria-hidden="true"></i>
                        <p class="noContent-title">تاکنون هشداری به ثبت نرسیده است</p>
                        <a href="" class="tgju-btn  m-top tgju-btn-primary" @click.prevent="open_alert_add_modal()"><i class="uil uil-plus-circle" style="display: inline-block;"></i> ایجاد هشدار</a>
                    </div>
                </div>

                <!-- <div v-if="!alerts.length" class="popup-dash-empty">



                    <i class="uil uil-bell" aria-hidden="true"></i>
                    <span>تاکنون هشداری به ثبت نرسیده است</span>
                    <a href="" class="tgju-btn  m-top tgju-btn-primary" @click.prevent="open_alert_add_modal()" v-tooltip="'ایجاد هشدار'"><i class="uil uil-plus-circle" style="display: inline-block;"></i> ایجاد هشدار</a>
                </div> -->
                <div class="list-row l-head" v-if="sidebarShow == true && !isMobile">
                    <div class="list-col l-title">
                        <span>تاریخچه هشدارها</span>
                    </div>
                </div>
                <div class="tgju-widget-content full-padding" v-if="sidebarShow == true">
                    <slot v-if="widgetLoad">
                        <div class="tgju-widget-content full-padding">
                            <div class="text-center p-2">
                                <div class="widget-loading"></div>
                            </div>
                        </div>
                    </slot>
                    <slot v-else>

                        <slot v-if="!isMobile && history.length">
                            <table class="widget-table widget-table-lg">
                                <thead>
                                    <tr>
                                        <th class="w60 text-center">#</th>
                                        <th>عنوان هشدار</th>
                                        <th class="border-l text-center hide-table-r hide-640">شاخص</th>
                                        <th class="border-l text-center">توضیحات</th>
                                        <th class="border-l text-center hide-table-r hide-640 w300">ساعت و تاریخ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="history_item in history" :key="history_item.id">
                                        <td class="border-l bg-gray w60 text-center"> {{ history_item.alert_id }}</td>
                                        <td class="border-l bg-gray"> {{ history_item.title }} </td>
                                        <td class="border-l text-center hide-table-r hide-640">{{ $helpers.json_pars(history_item.asset)['label'] }}</td>
                                        <td class="border-l text-center">{{ history_item.body }}</td>
                                        <td class="border-l text-center hide-table-r hide-640 w300">{{ toRelative(history_item.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </slot>
                        <slot v-if="isMobile && history.length">
                            <table class="widget-table widget-table-lg" style="margin-bottom: 15px !important;">
                                <thead>
                                    <tr>
                                        <th>لیست هشدارها</th>
                                    </tr>
                                </thead>
                            </table>
                            <div :class="'boxed-list-row '+ checkChange(history_item.body)" v-for="history_item in history" :key="history_item.id">
                                <div class="l-r-title">
                                    {{ history_item.title }}
                                    <span>
                                        {{ toRelative(history_item.created_at) }}
                                    </span>
                                </div>
                                <div class="l-r-item r-w-50 b-l b-b-0"> {{ $helpers.json_pars(history_item.asset)['label'] }}</div>
                                <div class="l-r-item r-w-50 b-b-0">{{ history_item.body }}</div>
                            </div>
                        </slot>

                        <div  v-if="!history.length" class="noWidget noContent">
                            <div>
                                <i class="uil uil uil-bell noContent-icon" aria-hidden="true"></i>
                                <p class="noContent-title">تاریخچه ای برای نمایش موجود نمی باشد</p>
                            </div>
                        </div>

                        <!-- <div v-if="!history.length" class="popup-dash-empty">
                            <i class="uil uil-bell" aria-hidden="true"></i>
                            <span>تاریخچه ای برای نمایش موجود نمی باشد</span>
                        </div> -->
                    </slot>
                </div>
            </slot>
        </div>
    </div>
</div>
</template>

<script>
// این کامپوننت دارای اجزای ماژول هشدار ها در داشبورد می باشد
import moment from "moment-jalaali";
export default {
    name: 'Alert',
    components: {},
    data: function () {
        return {
            sidebarShow: false,
            alerts: [],
            history: [],
            markets: [],
            widgetLoad: true,
            isMobile:false,
            menus: [
                { title: 'راهنما', modal: 'help' }
            ],
        }
    },
    mounted() {
        this.getAlerts();
        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        checkChange(item){
            if(item.includes("▼")){
                return 'l-r-low';
            }else if(item.includes("▲")){
                return 'l-r-high';
            }
        },
        get_modal(name){
            if(name == 'help'){
                this.open_help_modal();
            }
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت باز کردن مودال افزودن و یا ویرایش هشدار جدید می باشد و همچنین کال بک مودال را هندل می نماید
        open_alert_add_modal(id = null) {
            let callbackEvent = this.$router.currentRoute.name + '_AddAlert_' + new Date().getTime();

            let action = (id) ? 'edit' : 'add';
            let alert = null;

            if (id) {
                let index = this.$helpers.getIndex(this.alerts, id);
                alert = this.alerts[index];
            }
            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'AlertAddEdit.vue',
                config: {
                    title: (action == 'edit') ? 'ویرایش هشدار' : 'افزودن هشدار',
                    icon: (action == 'edit') ? 'uil uil-edit-alt with-icon' : 'uil uil-plus-circle with-icon',
                    smallModal: true,
                    data: {
                        action,
                        alert,
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                // آرایه ای از خطا های رایج
                let server_response = {
                    field_empty: 'فیلد ها الزامی می باشند',
                    notification_required: 'حداقل یک نوع دریافت الزامی می باشد',
                };

                // if ( response.action == 'addAlert' ) {
                //  آماده سازی دیتای درخواست ای پی آی

                let api_data = {
                    id: id,
                    data: JSON.stringify(response.data.alert)
                };

                //ارسال درخواست ای پی آی
                this.$helpers.makeRequest('POST', '/alert/create-edit-alert', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);

                    // در صورتی در خواست ای پی آی خطایی داشت پیام مناسب نمایش دهد
                    if (api_response.data.response.status === 'error') {
                        this.$swal.fire({
                            icon: "error",
                            title: api_response.data.response.message,
                            showConfirmButton: !1,
                            timer: 2000
                        });
                        return 0;
                    }

                    // در صورتی که در خواست ای پی آی موفقیت آمیز بود
                    if (api_response.data.response.status === 'success') {
                        this.$swal.fire({
                            icon: "success",
                            title: (action == 'edit') ? 'هشدار با موفقیت ویرایش شد' : 'هشدار با موفقیت افزوده شد',
                            showConfirmButton: !1,
                            timer: 2000
                        });
                        this.getAlerts()
                        return 0;
                    }
                });
                // } 
            });
        },
        // این متد لیست هشدار های کاربر را دریافت می نماید
        getAlerts() {
            let api_data = {};

            //ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/alert/alerts', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                this.alerts = api_response.data.response.detail.alerts;
                this.markets = api_response.data.response.detail.markets;
                this.widgetLoad = false;
            });
        },
        // این متد برای درخواست تاریخچه ی هشدار های که کاربر دریافت نموده است می باشد
        getHistory(id) {
            this.history = [];
            this.widgetLoad = true;
            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('GET', '/alert/history/' + id).then(api_response => {
                this.$helpers.api_alert(api_response);
                this.history = api_response.data.response.detail.history;
                this.widgetLoad = false;
            });
        },
        // این متد برای حذف هشدار استفاده می شود
        removeAlert(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let index = this.$helpers.getIndex(this.alerts, id);

                    this.alerts.splice(index, 1);
                    this.$helpers.makeRequest('DELETE', '/alert/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.data.response.status == 'success') {
                            this.$swal.fire({
                                icon: "success",
                                title: "هشدار با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف هشدار رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // این متد برای دریافت قیمت شاخص مورد نظر می باشد
        getPrice(symbol) {
            var market = this.markets.find(item => item.item_id == symbol);
            return market ? market.p : 'مقداری ندارد';
        },
        showSidebar(id) {
            this.sidebarShow = true;
            this.getHistory(id);
        },
        hideSidebar() {
            this.sidebarShow = false;
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Alert.vue',
                title: 'هشدارها',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
